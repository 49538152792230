<template>
  <ContentCard :card-height="40">
    <template v-slot:header>
      <h3 class="primary-text" style="margin-top: 0.5rem">{{ title }}</h3>
      <AddAttributeButton
        v-if="!isPublic"
        button-title="Add One"
        :open-modal="false"
      >
        <EditProfileDropdown
          v-if="!expertId"
          @item-clicked="onSelectItem"
          :dropdown-menu-items="dropdownMenuItems"
          :selected-items="items"
        />
      </AddAttributeButton>
    </template>
    <template v-slot:body>
      <div
        v-if="!items.length"
        class="d-flex flex-column h-95 align-items-center justify-content-center"
      >
        <el-avatar
          v-if="!isPublic"
          :size="200"
          :src="'/media/emptyCard/empty_1.png'">
          <img :src="'/media/emptyCard/empty_1.png'" :alt="'empty_card'"/>
        </el-avatar>
        <h4 v-if="!isPublic" class="primary-text text-center mt-8">
          Please add some info
        </h4>
        <h4 v-else class="primary-text text-center">
          This user hasn't added any {{ title }}.
        </h4>
      </div>
      <div class="d-flex justify-content-start flex-wrap pt-7">
        <div
          v-for="(industry, index) in items"
          :key="index"
          class="mb-3 btn-container"
        >
          <el-button
            class="industry-name btn-success"
            style="color: white"
            size="mini"
            round
            >{{ industry.name
            }}<el-icon
              v-if="!isPublic"
              @click="$emit('item-to-delete', industry.id)"
              class="el-icon--right cursor-pointer"
              ><CircleCloseFilled /></el-icon
          ></el-button>
        </div>
      </div>
    </template>
  </ContentCard>
</template>

<script>
import ContentCard from "../../common/components/ContentCard";
import EditProfileDropdown from "./EditProfileDropdown";
import { CircleCloseFilled } from "@element-plus/icons-vue";
import { useRoute } from "vue-router";
import { computed } from "vue";
import AddAttributeButton from "@/common/components/AddAttributeButton";
export default {
  name: "ExpertSelectionsCard",
  components: {
    AddAttributeButton,
    EditProfileDropdown,
    ContentCard,
    CircleCloseFilled,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    dropdownMenuItems: {
      type: Array,
      required: true,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const route = useRoute();
    const expertId = computed(() => route.params.expertId);
    const onSelectItem = (item) => {
      context.emit("item-to-add", item);
    };

    return { onSelectItem, expertId };
  },
};
</script>

<style scoped lang="scss">
.d-flex.h-95 {
  height: 95% !important;
}
.text {
  margin-left: 0.5rem;
}
.btn-container {
  margin-right: 0.25rem;
  .industry-name {
    cursor: default;
  }
}
</style>
