<template>
  <div
    v-show="showButton"
    @click="$emit('buttonClicked')"
    ref="buttonRef"
    class="button-container primary-transparent-background cursor-pointer text-center"
    :data-bs-toggle="openModal ? 'modal' : ''"
    :data-bs-target="openModal ? `#${modalId}` : ''"
    data-kt-menu-trigger="click"
    data-kt-menu-attach="parent"
    data-kt-menu-placement="top"
    data-kt-menu-flip="bottom"
    style="margin-left: 0.5rem"
  >
    <span
      class="fw-bold primary-color button-title"
      style="white-space: nowrap"
    >
      {{ buttonTitle }}
    </span>
  </div>
  <slot />
</template>

<script>
export default {
  name: "AddAttributeButton",
  emits: ["buttonClicked"],
  props: {
    buttonTitle: {
      type: String,
      required: true,
    },
    openModal: {
      type: Boolean,
      default: false,
    },
    modalId: {
      type: String,
      required: false,
    },
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    clickButton() {
      this.$refs.buttonRef.click();
    },
  },
};
</script>

<style scoped lang="scss">
.button-container {
  color: var(--el-button-background-color);
  background-color: #ecf5ff;
  border: 1px solid #b3d8ff;
  padding: 0.3rem 1.5rem;
  border-radius: 3px;
  .button-title {
    font-size: 0.9em;
  }
}
</style>
