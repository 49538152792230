<template>
  <div class="fs-8 mb-2 grey-text">
    {{ getTitle() }}
  </div>
  <div class="d-flex align-items-center justify-content-between">
    <h6 class="primary-text">
      {{ getSubTitle() }}
    </h6>
    <el-button
      v-if="!isPublic"
      @click="$emit('buttonClicked')"
      :data-kt-menu-trigger="isPublic || isTypeDiscount ? '' : 'click'"
      :data-kt-menu-attach="isPublic || isTypeDiscount ? '' : 'parent'"
      :data-kt-menu-placement="isPublic || isTypeDiscount ? '' : 'top'"
      :data-kt-menu-flip="isPublic || isTypeDiscount ? '' : 'bottom'"
      class="btn btn-primary-rounder btn-sm py-2"
      :class="getButtonClass()"
      size="mini"
      round
      >{{ getButtonText() }}</el-button
    >
    <slot />
  </div>
</template>

<script>
import { useRoute } from "vue-router";

export default {
  name: "RateCard",
  emits: ["buttonClicked"],
  props: {
    rate: {
      type: Object,
      required: true,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
    isTypeDiscount: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const route = useRoute();
    const expertId = route.params.expertId;

    const getTitle = () => {
      let title = "";
      if (props.isTypeDiscount) {
        switch (props.rate.expertRates.type) {
          case "perHour":
            title = "Discount on rate per hour";
            break;
          case "perDay":
            title = "Discount on base rate";
            break;
          case "perIntro":
            title = "Intro Discount";
            break;
          default:
          //
        }
      } else {
        switch (props.rate.expertRates.type) {
          case "perHour":
            title = "Rate per hour";
            break;
          case "perDay":
            title = "Rate per day";
            break;
          case "perIntro":
            title = "Networking rate";
            break;
          default:
          //
        }
      }
      return title;
    };

    const getSubTitle = () => {
      let title = "";
      if (props.isTypeDiscount) {
        switch (props.rate.expertRates.type) {
          case "perHour":
            title = `${Number(
              props.rate.discountPercentage
            ).toLocaleString()} % on rate per hour`;
            break;
          case "perDay":
            title = `${Number(
              props.rate.discountPercentage
            ).toLocaleString()} % on rate per day`;
            break;
          case "perIntro":
            title = `${Number(
              props.rate.discountPercentage
            ).toLocaleString()} % per intro`;
            break;
          default:
          //
        }
      } else {
        switch (props.rate.expertRates.type) {
          case "perHour":
            title = `€${Number(props.rate.rate).toLocaleString()} / per hour`;
            break;
          case "perDay":
            title = `€${Number(props.rate.rate).toLocaleString()} / per day`;
            break;
          case "perIntro":
            title = `€${Number(props.rate.rate).toLocaleString()} / per intro`;
            break;
          default:
          //
        }
      }
      return title;
    };

    const getButtonText = () => {
      if (props.isTypeDiscount) {
        if (props.rate.discountPercentage === 0) {
          return "Set";
        }
        return "Edit";
      }
      if (props.rate.rate === 0) {
        return "Set";
      }
      return "Edit";
    };

    const getButtonClass = () => {
      if (props.isTypeDiscount) {
        if (props.rate.discountPercentage === 0) {
          return "btn-primary-rounder";
        }
        return "btn-success";
      } else {
        if (props.rate.rate === 0) {
          return "btn-primary-rounder";
        }
        return "btn-success";
      }
    };

    return { expertId, getTitle, getSubTitle, getButtonText, getButtonClass };
  },
};
</script>

<style scoped lang="scss">
.button-container {
  border-style: solid;
  padding: 1rem 0.15rem;
  .button-title {
    padding-left: 0.5rem;
  }
}
.one-line {
  display: flex;
  align-items: center;
}
.multiline {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
