<template>
  <apexchart :series="series" :options="options" type="bar" height="250" />
</template>

<script>
import { ref } from "vue";

export default {
  name: "BarGraph",
  props: {
    series: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const options = ref({
      chart: {
        fontFamily: "inherit",
        type: "bar",
        toolbar: {
          show: false,
        },
        grid: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "85%",
          borderRadius: 15,
        },
      },
      legend: {
        show: true,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: props.categories,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        min: 0,
        tickAmount: 5,
        max: 100,
        labels: {
          formatter: (value) => {
            return Number(value).toLocaleString() + "%";
          },
        },
      },
      fill: {
        opacity: 1,
      },
      stroke: {
        show: true,
        colors: ["#15A6FA"],
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: (value) => {
            return Number(value).toLocaleString() + "%";
          },
        },
      },
      colors: ["#91D6FC"],
      grid: {
        borderColor: "#E9EEF1",
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    });

    return { options };
  },
};
</script>

<style scoped></style>
