<template>
  <div
    class="card mb-5 mb-xl-10 card-container overflow-scroll"
    :style="{ height: height }"
  >
    <div
      class="d-flex align-items-center border-0"
      style="padding-top: 1rem; padding-left: 1rem; padding-right: 1rem"
    >
      <slot name="header"></slot>
    </div>
    <div class="card-body pt-0 pb-5">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentCard",
  props: {
    cardHeight: {
      type: Number,
      required: true,
    },
  },
  computed: {
    height: function () {
      return this.cardHeight + "rem";
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  margin-left: 0.5rem;
}
</style>
