<template>
  <div
    id="data-kt-menu"
    class="menu menu-sub edit-profile-dropdown menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px"
    data-kt-menu="true"
  >
    <div
      class="menu-item px-5"
      v-for="(item, index) in dropdownMenuItems"
      :key="index"
    >
      <div
        :class="[
          'menu-link',
          'px-5',
          isItemSelected(item.id) && 'disabledLink',
        ]"
        @click="$emit('item-clicked', item)"
      >
        <span :class="isItemSelected(item.id) && 'disabled-link-color'"
          >{{ item.name ? item.name : item }} {{ unit }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "EditProfileDropdown",
  components: {},
  props: {
    dropdownMenuItems: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selectedItems: {
      type: Array,
      default: function () {
        return [];
      },
    },
    unit: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const isItemSelected = (id) => {
      return props.selectedItems.find((item) => item.id === id);
    };

    return { isItemSelected };
  },
});
</script>
<style lang="scss">
.disabledLink {
  pointer-events: none;
  .disabled-link-color {
    color: #c8cad5;
  }
}
.edit-profile-dropdown {
  max-height: 40%;
  overflow-y: auto;
}
</style>
