<template>
  <div
    class="investment-range-type border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3 cursor-pointer"
    :class="rangeType.selected ? 'light-primary-background' : ''"
    @click="!isPublic && $emit('item-clicked', rangeType)"
  >
    <div class="text-center">
      <span class="svg-icon svg-icon-1 svg-icon-success">
        <inline-svg
          width="150"
          height="150"
          :fill="rangeType.selected ? '#ffffff' : '#94AEBD'"
          :src="icon"
        />
      </span>
      <div
        :class="[
          'fw-bold',
          'fs-6',
          'mt-2',
          'range-type',
          {
            'text-white': rangeType.selected,
            'grey-text': !rangeType.selected,
          },
        ]"
      >
        {{ rangeType.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { computed } from "vue";
import InlineSvg from "vue-inline-svg";

export default {
  name: "InvestmentRangeType",
  components: {
    InlineSvg,
  },
  props: {
    rangeType: {
      type: Object,
      required: true,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  setup() {
    const route = useRoute();
    const expertId = computed(() => route.params.expertId);

    return { expertId };
  },
};
</script>

<style scoped lang="scss">
.investment-range-type {
  .range-type {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.light-primary-background {
    background-color: #6ac8fb !important;
  }
}
</style>
